import React, { useEffect, useState } from 'react';
import './App.css';
import Root from './Root';
import StandardAligner from './StandardAligner';
import PiggybackAligner from './PiggybackAligner';
import SIACalculator from './SIACalculator';
import AboutUs from './AboutUs';
import Register from './Register';
import NewUserValidation from './NewUserValidation';
import Login from './Login';
import Publications from './Publications'

import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import UserPage from './UserPage';
import { UserContext } from './UserContext';
import { Authenticate } from './services/Authentication'
import { USERS_ENABLED } from './Settings';
import ResetPassword from './ResetPassword';
import Maintenance from './Maintenance';

export default function App() {

  const [username, setUsername] = useState(["",""]);

  useEffect(() => {
    async function tryAuthentication(){
      if(window.location.pathname !== "/maintenance"){
        try{
          let userID = await Authenticate();
          setUsername(userID);       
        }catch(error){
          window.location.href = "/maintenance"
        }
      }
    }
    if (USERS_ENABLED) tryAuthentication();
  }, [])
  

  return (
    <UserContext.Provider value={username}>
      <Router>
        <script async src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" 
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM">
        </script>
        
        <Routes>
          <Route path="/" element={<Root/>}/>
          <Route path="/standard" element={<StandardAligner/>}/>
          <Route path="/piggyback" element={<PiggybackAligner/>}/>
          <Route path="/SIA" element={<SIACalculator/>}/>
          <Route path="/about" element={<AboutUs/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/activate" element={<NewUserValidation/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/user" element={<UserPage/>}/>
          <Route path="/resetpassword" element={<ResetPassword/>}/>
          <Route path="/maintenance" element={<Maintenance/>}/>
          <Route path="/publications" element={<Publications/>}/>
        </Routes>
      </Router>
    </UserContext.Provider>
  )
}