import React from 'react'
import './aboutUs.css'
import BarHeader from './common/BarHeader'
import toricLogo from './img/toric-logo.png'
import {RiInformationLine} from 'react-icons/ri'
import { IoIosPaper } from "react-icons/io";
import { SiPubmed } from "react-icons/si";

export default function AboutUs() {
  return (
    <div>
        <BarHeader/>
        <div className='aboutUs'>
          <div className='aboutUsContent'>
              <span className='aboutTitle'>
                <span><IoIosPaper className='aboutTitleIcon'/>Publications</span>
              </span>
              <div className='aboutUsText'>

                <div>
                    <SiPubmed size={"2vw"}/>
                    <span style={{fontStyle: 'italic'}}>"Buonsanti D, Cooke DL, Hoffer KJ, Savini G, Lupardi E, Buonsanti J, Aramberri J. A novel method to optimize personal IOL constants. Am J Ophthalmol. 2024 Aug 30:S0002-9394(24)00389-1"</span>
                    <br></br>
                    <a target="_blank" href='https://pubmed.ncbi.nlm.nih.gov/39218385/' className='about-link'>
                    Read in PubMed
                    </a>
                </div>

                <br></br>

                <div>
                    <SiPubmed size={"2vw"}/>
                    <span style={{fontStyle: 'italic'}}>"Buonsanti D, Coutinho CP, Hoffer KJ, Savini G. Meridional analysis for calculation of the toric power of phakic IOLs. Eur J Ophthalmol. 2024 Apr 18:11206721241245747."</span>
                    <br></br>
                    <a target="_blank" href='https://pubmed.ncbi.nlm.nih.gov/38632941/' className='about-link'>
                    Read in PubMed
                    </a>
                </div>

                <br></br>

                <div>
                    <SiPubmed size={"2vw"}/>
                    <span style={{fontStyle: 'italic'}}>"Buonsanti D, Raimundo M, Findl O. Online intraocular lens calculation. Curr Opin Ophthalmol. 2024 Jan 1;35(1):11-16."</span>
                    <br></br>
                    <a target="_blank" href='https://pubmed.ncbi.nlm.nih.gov/37922421/' className='about-link'>
                    Read in PubMed
                    </a>
                </div>

              </div>
          </div>
      </div>
    </div>
  )
}
